<template>
    <div v-if="visible" :data-val="file.type" :class="[{ document: type === 'column' }, { list: type === 'list' }, { selected: selected }, $mq]" @click="detailFile(file)">
        <template v-if="type == 'column'">
            <div class="image-container" :class="{ haveStar: file.highlight }" v-if="file.file">
                <div v-if="file.highlight" class="is-highlighted"></div>
                <div v-if="file.preview" :class="['clg-image']">
                    <img v-if="file.preview" :src="file.preview" />
                </div>
                <!-- <div v-else-if="file.file && file.type !== 2 && (file.file.endsWith('.png') || file.file.endsWith('.jpg') || file.file.endsWith('.jpeg') || file.file.endsWith('.svg'))" :class="['clg-image']">
                    <img :src="file.file" />
                </div> -->
                <!-- <cld-image
                    v-else-if="file.file && file.type !== 2 && (file.file.endsWith('.png') || file.file.endsWith('.jpg') || file.file.endsWith('.jpeg') || file.file.endsWith('.svg'))"
                    class="clg-image"
                    :publicId="file.file"
                    type="fetch"
                    loading="lazy"
                    :class="{
                        hasPreview: true
                    }"
                >
                    <cld-transformation width="150" crop="fill" fetchFormat="auto" />
                </cld-image> -->

                <template v-else-if="file.file && file.item_type === 'image'">
                    <div v-if="file.preview" :class="[file.preview ? 'clg-image pdf-image' : 'pdf-background']">
                        <img v-if="file.preview" :src="file.preview" />
                    </div>

                    <div v-else :class="[file.file ? 'clg-image pdf-image' : 'image-background']">
                        <img v-if="file.file" :src="'https://res.cloudinary.com/intowin/image/fetch/c_fill,f_auto,h_500,r_3/' + file.file" />
                    </div>
                </template>
                <!-- PDF PREVIEW -->
                <template v-else-if="file.file && file.item_type === 'pdf'">
                    <!-- si hay preview, mostrar la preview -->
                    <div v-if="file.preview" :class="[file.preview ? 'clg-image pdf-image' : 'image-background']">
                        <img v-if="file.preview" :src="file.preview" />
                    </div>
                    <!-- si no hay preview mostrar preview de Cloudinary del mismo fichero pdf, o bien el background con el ícono "pdf" -->
                    <div v-else :class="[file.file ? 'clg-image pdf-image' : 'image-background']">
                        <img v-if="file.file" :src="'https://res.cloudinary.com/intowin/image/fetch/c_fill,f_auto,h_500,r_3/' + file.file" />
                    </div>
                </template>
                <div v-else-if="file.file && file.item_type === 'word'" class="word-background"></div>
                <div v-else-if="file.file && file.item_type === 'excel'" class="excel-background"></div>
                <div v-else-if="file.file && file.item_type === 'zip'" class="zip-background"></div>
                <div v-else-if="file.file && file.item_type === 'video'" class="video-background"></div>
                <div v-else class="default-background"></div>
            </div>
            <div v-else class="image-container" :class="{ haveStar: file.highlight }">
                <div class="image" :class="{ hasPreview: file.file }" :style="file.file ? { backgroundImage: 'url(' + file.file + ')' } : {}"></div>
            </div>

            <div class="title" :class="{ pdf: file.file }">
                <div class="name" :title="file.name">{{ file.name }}</div>
            </div>
        </template>
        <template v-else>
            <div class="filetype" :class="{ pdf: file.file && file.file.endsWith('.pdf') }" :data-val="file.item_type"></div>
            <div class="info-file" :class="{ haveStar: file.highlight }">
                <div class="title" :class="{ 'title-search-result': search }">
                    {{ file.name }}
                </div>
                <div v-if="search && paths.length > 0" class="meta">
                    <!-- <div v-if="paths.length > 0">{{ $t('library.home') }}</div>
                    <div v-for="(path, index) in paths" :key="index">&nbsp; > {{ path.name }}</div> -->
                    <div v-if="paths.length > 0" class="path">
                        <div>{{ $t('library.home') }}</div>
                        <div v-for="(path, index) in paths" :key="index" class="folder-name">
                            {{ ' > ' + path.name }}
                        </div>
                    </div>
                </div>
                <div v-else-if="search && paths.length < 1" class="meta">
                    {{ $t('library.home') }}
                </div>
            </div>
            <div class="is-highlighted" v-if="file.highlight"></div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'FileItem',

    props: {
        file: {
            type: [Array, Object],
            default: []
        },
        type: {
            type: String,
            default: 'column'
        },
        search: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            selected: true,
            windowWidth: window.innerWidth
        }
    },
    filters: {
        moment: function (date, format) {
            return date ? moment(date).format(format) : ''
        }
    },
    computed: {
        isMobile() {
            return this.windowWidth <= 767
        },
        visible() {
            if (!this.file) return false
            if (!this.file.roles || this.file.roles.length == 0) return true
            else {
                var user_id = this.$store.getters['loginUser/getLocalEmployee']
                var employee = this.$store.getters['employee/getEmployee'](user_id)
                if (employee) {
                    let userRole = this.$store.getters['employee/getRol'](employee.rol)
                    if (userRole.level >= 8) return true
                    return this.file.roles.includes(userRole.id)
                } else return false
            }
        },
        categories() {
            return Object.values(this.$store.getters['library/getCategories'])
        },
        allLibraryFoldersObj() {
            return this.$store.getters['library/getAllLibraryFolders']
        },
        paths() {
            let paths = []
            const parentId = this.file.parent_id ? this.file.parent_id : this.file.category_id

            paths = this.searchRecursiveCategories(parentId, [])

            if (paths.length <= 1) {
                return paths
            } else {
                if (this.isMobile) {
                    return [{ id: false, name: '...' }, paths[paths.length - 1]]
                } else {
                    return [paths[0], { id: false, name: '...' }, paths[paths.length - 2], paths[paths.length - 1]]
                }
            }
        }
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth
        },
        searchRecursiveCategories(folderId, params) {
            const path = params

            const allFolders = this.allLibraryFoldersObj

            if (allFolders[folderId]) {
                if (allFolders[folderId].parent_id) {
                    this.searchRecursiveCategories(allFolders[folderId].parent_id, path)
                }

                path.push({
                    name: allFolders[folderId].name,
                    id: allFolders[folderId].id
                })
            }

            return path
        },

        detailFile(file) {
            this.$router.push({ path: `/library/${file.id}` })
        }
    },
    watch: {},
    created() {
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {},
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style lang="scss" scoped>
.document {
    position: relative;
    width: 250px;
    @include interaction();
    @include display-flex();
    @include flex-direction(column);
    @include align-items($val: flex-start);
    @include justify-content();
    @include border-radius(4px);
    width: 100%;
    min-height: 150px;
    overflow: hidden;
    gap: 4px;

    // DEFAULT IMAGES IF TYPE = NULL
    .image {
        @include background($size: 32px, $color: $neutro-t60, $image: img('default_file.svg'));
    }

    // IMAGES BY TYPES
    &[data-val='default'] {
        .image {
            @include background($size: 32px, $position: center, $image: img('default_file.svg'));
        }
    }

    &[data-val='pdf'] {
        .image {
            @include background($size: 48px, $position: center, $image: img('PDF.svg'));
        }
    }

    &[data-val='excel'] {
        .image {
            @include background($size: 48px, $position: center, $image: img('excel_icon.svg'));
        }
    }

    &[data-val='word'] {
        .image {
            @include background($size: 48px, $color: $neutro-t60, $image: img('word.svg'));
        }
    }
    &[data-val='video'] {
        .image {
            @include background($size: 32px, $position: center, $image: img('video.svg'));
        }
    }
    &[data-val='zip'] {
        .image {
            @include background($size: 88px, $position: center, $image: img('zip.svg'));
        }
    }
    &[data-val='image'] {
        .image {
            @include background($size: 48px, $position: center, $image: img('image.svg'));
        }
    }

    .image-container {
        position: relative;
        width: calc(100%);
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-items: center;
        background-color: $color-white;
        padding: 8px;
        border-radius: 4px;

        .is-highlighted {
            position: absolute;
            top: 4px;
            right: 4px;
            width: 28px;
            height: 28px;
            background-color: $color-white;
            @include background($image: img('star_main.svg'), $size: 18px);
            border-radius: 4px;
        }
        .clg-image {
            object-fit: contain;
            background-color: $inactive-t60;
            height: 100%;
            width: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .pdf-image {
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: 100%;
            object-fit: cover;
            overflow: hidden;
        }

        .image-background {
            @include background($size: 48px, $position: center, $image: img('image.svg'));
            background-color: $inactive-t60;
            height: 100%;
            width: 100%;
        }
        .pdf-background {
            @include background($size: 48px, $position: center, $image: img('PDF.svg'));
            background-color: $inactive-t60;
            height: 100%;
            width: 100%;
        }

        .word-background {
            @include background($size: 38px, $position: center, $image: img('word.svg'));
            background-color: $inactive-t60;
            height: 100%;
            width: 100%;
        }

        .excel-background {
            @include background($size: 48px, $position: center, $image: img('excel_icon.svg'));
            background-color: $inactive-t60;
            height: 100%;
            width: 100%;
        }
        .zip-background {
            @include background($size: 88px, $position: center, $image: img('zip.svg'));
            background-color: $inactive-t60;
            height: 100%;
            width: 100%;
        }

        .video-background {
            @include background($size: 32px, $position: center, $image: img('video.svg'));
            background-color: $inactive-t60;
            height: 100%;
            width: 100%;
        }

        .default-background {
            @include background($size: 32px, $position: center, $image: img('default_file.svg'));
            background-color: $inactive-t60;
            height: 100%;
            width: 100%;
        }
    }

    .image {
        // @include border-radius(3px);
        width: calc(100%);
        height: 120px;
        display: inline-block;

        &.hasPreview {
            @include background($size: contain, $color: $main-t90, $image: img('blank_document.svg'));
        }
    }

    .title {
        @include display-flex();
        background-position: left center !important;
        // margin-top: 3px;
        // height: 32px;
        height: 60px;
        width: 100%;
        font-size: 16px;

        .name {
            @include display-inline-flex();
            @include align-items();
            @include font-size(md);
            @include text-ellipsis($line: 3);
            // line-height: 1.2; // PORFABOR NO TOCAR
            line-height: 1.1em;
            color: $neutro-s80;
            width: 100%;
            height: calc(1.1em * 3);
            // margin-top: 3px;
            padding-top: 4px;
            overflow-wrap: break-word;
            font-family: $text-bold;
            // font-weight: 300;
            font-size: 16px;
            padding: 0px 4px 4px 4px;

            // &:before {
            //     display: inline-block;
            //     height: 14px;
            //     width: 14px;
            //     content: '';
            //     margin-right: 3px;
            // }
        }
    }

    .meta {
        width: 100%;
        font-size: 16px;
        color: #222222;

        .update {
            @include font-size(s);
            color: $neutro-s70;
            float: left;
            width: calc(100% - 15px);
            font-family: $conden-light;
        }

        .highlight {
            float: right;
            width: 15px;
            height: 15px;
            top: 12px;
            right: 12px;
            position: absolute;
            padding: 12px;
            @include background($image: img('star_main.svg'), $size: 20px, $color: #fff);
        }
    }
}

// list styles
.list {
    width: 100%;
    background-color: #fff;
    height: 56px;
    padding: 8px 0px;
    @include border-radius(4px);
    @include display-flex();
    @include align-items();
    @include justify-content(flex-start);
    cursor: pointer;

    .filetype {
        width: 50px;
        height: 50px;
        // IMAGES BY TYPES
        &[data-val='default'] {
            @include background($size: 18px, $position: center, $image: img('default_file.svg'));
        }

        &[data-val='pdf'] {
            @include background($size: 40px, $position: center, $image: img('PDF.svg'));
        }

        &[data-val='excel'] {
            @include background($size: 24px, $position: center, $image: img('excel_icon.svg'));
        }

        &[data-val='word'] {
            @include background($size: 18px, $position: center, $image: img('word.svg'));
        }
        &[data-val='video'] {
            @include background($size: 18px, $position: center, $image: img('video.svg'));
        }
        &[data-val='image'] {
            @include background($size: 18px, $position: center, $image: img('image.svg'));
        }
        &[data-val='zip'] {
            @include background($size: 40px, $position: center, $image: img('zip.svg'));
        }
    }

    .info-file {
        @include display-flex();
        @include flex-direction(column);
        width: calc(100%);
        padding-right: 8px;
        gap: 8px;

        .title {
            // @include font-size(md);
            font-size: 16px !important;
            @include text-ellipsis($line: 2);
            width: calc(100%);
            font-family: $text-sbold;
            color: $neutro-s80;
        }

        .title-search-result {
            @include text-ellipsis($line: 1);
        }
        .meta {
            display: flex;
            // @include font-size(sm);
            font-size: 16px;
            @include text-ellipsis();
            width: 100%;
            font-family: $text;
            // color: $neutro-s50;

            .path {
                width: 100%;
                max-width: 100%;
                display: flex;
                flex-direction: row;
                font-size: 16px;
                gap: 8px;

                .folder-name {
                    width: fit-content;
                    max-width: 32ch;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

        &.haveStar {
            width: calc(100% - 90px);
        }
    }

    .is-highlighted {
        width: 25px;
        height: 25px;
        @include background($image: img('star_main.svg'), $size: 25px);
        min-width: 25px;
        min-height: 25px;
    }
}
</style>

<!-- // PORTRAIT STYLES -->
<style lang="scss" scoped>
.portrait.list {
    .info-file {
        width: calc(100% - 50px);
        .title {
            .name {
                font-family: 'SourceSans3-SemiBold';
                font-weight: 600;
                font-size: 16px;
            }
            .meta {
                .update {
                    @include font-size(xs);
                }
            }
        }
    }
}
</style>
